// Directions
$dir: ltr;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

html {
  --right: right;
  --left: left;
  --dir: ltr;
  --productListTranslate: 150%;
  --percentage: 100%;
  --icx-left-solid-arrow: '\e903';
  --icx-right-solid-arrow: '\e904';
  --icx-arrow-right: '\e908';
}

@import '~bootstrap';

// Base Files
@import 'base/variables';
@import 'base/reset';
@import 'base/mixins';
@import 'base/colors';
@import 'base/typography';
@import 'base/custom-classes';
@import 'base/custom-modal';
@import 'base/general';


// Shared Components
@import 'shared/all';

// Modules
@import './modules/auth/all';
@import './modules/calendar/all';
@import './modules/reporting/all';
@import './modules/user-profile/all';
@import './modules/ManageStadium/all';
@import './modules/Coupon&Discount/all';
@import './modules/billing/all';
@import './modules/coaches/all';
@import './modules/loyality/all';
@import './modules/malaebLive/all';




@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;700&display=swap');



html[dir='rtl'] {
  --dir: rtl;
  --left: right;
  --right: left;
  --icx-left-solid-arrow: '\e904';
  --icx-right-solid-arrow: '\e903';
  --icx-arrow-right: '\e908';
  --productListTranslate: -150%;
  --percentage: -100%;

}