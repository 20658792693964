.usage-module {
    .usageTitle {
        width: fit-content;
        padding-bottom: 4px;
        border-bottom: 3px solid $lime;
    }

    .fa-circle-arrow-left {
        top: 18px !important;
        font-size: 2.7rem;

        [dir='rtl'] & {
            right: -40px;
            transform: rotateY(180deg);
        }

        [dir='ltr'] & {
            left: -22px;

        }


    }

    .rectangleBox {
        width: 160px;
        height: 85px;
        border-radius: 6.936px;
        background: #FFF;
        box-shadow: 0px 5.54894px 5.54894px 0px rgba(0, 0, 0, 0.05);

        .titleBox {
            background-color: #E1FFBD;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .numberBox {
            height: 55px;

        }
    }

    .inputWrapper {
        background-color: rgba(250, 250, 250, 0.93);
        border-radius: 10px;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;

        * {
            color: var(--Label-Colors-LC-L-Secondary, rgba(60, 60, 67, 0.60));
        }

        .fa-magnifying-glass {
            position: absolute;

            [dir="ltr"] & {
                right: 58%;

            }

            [dir="rtl"] & {
                left: 58%;

            }
        }

        .fa-circle-xmark {
            position: absolute;
            right: 1%;
            top: 10px;
            cursor: pointer;
            font-size: 1.2rem;

            [dir="rtl"] & {
                left: 1%;

            }
        }

        input.form-control {
            background-color: rgba(250, 250, 250, 0.93) !important;
            padding: 8px 0px !important;
            font-size: 15px !important;
            width: 57% !important;
            border-radius: 10px !important;
        }

    }

    table {
        &.table tr:first-child th:first-child {
            border-radius: 0 !important;
        }


        &.table tr:first-child th:last-child {
            border-top-right-radius: 0px !important;
        }

        &.table .thead-dark th,
        td {
            font-size: $text-caption;
            font-weight: 400;
        }

    }
}