.createmodal {

    .modal-header,
    .modal-footer {
        justify-content: center !important;
        border: none !important;

        .modal-title {
            font-weight: 600;
        }

        .fa-xmark {
            top: 50px;
            right: 30px;
        }
    }

    .modal-body {
        label {
            font-size: 12px !important;
            color: #2B2F32;
            font-weight: 400;
        }

        .control-field {
            border-radius: 8px;
            border: transparent;
            padding: 0 !important;

            .control-field__body {
                border-radius: 8px;
                padding: 0.3rem;
                border: 1px solid var(--black-1, #CFD3D4);

                input,
                .control-field__select {
                    padding-top: 7px;
                    color: #5a5c5d;
                }

                label.control-field__label,
                label {
                    font-size: 12px !important;
                    color: #23272a;
                    font-weight: 400;
                }
            }

        }

        .timePicker {
            button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium {
                margin-bottom: 20px;
            }

            label {
                color: #5E6366 !important;
                font-weight: 400;
                font-size: 10px !important;

            }
        }

        .submit {
            width: 50%;
            margin-bottom: 20px;
            font-weight: 700;
            font-size: 23px;
        }

        .currency {
            top: 37px;

            [dir="ltr"] & {
                right: 17px;
            }

            [dir="rtl"] & {
                left: 17px;

            }
        }

    }

}