.create-booking {
  .modal-header {
    background-color: $green;
    color: $white;
    .close {
      > span {
        border: 1px solid white;
        width: 30px;
        height: 30px;
        line-height: 28px;
        color: white;
        border-radius: 50%;
        display: block;
      }
    }
  }
  .create-footer {
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
      padding-block: 0.5rem;
    }
  }
}

//custom-input
.mybooking-input-wrapper {
  // change button style
  .btn-primary {
    min-width: 120px !important;
    height: 100%;
    background-color: $blue !important;
    outline: none !important;
    border-color: $blue;
    border-radius: 0;

    .fa-search {
      font-size: $text-title3;
    }
  }

  .btn:focus,
  .btn:active {
    outline: none !important;
    box-shadow: none;
  }
  // end button style
  .form-group {
    // margin-bottom: 0px !important;
    position: relative;

    label {
      &.form-control-custom-input {
        padding-top: 2.5em;
        font-size: 12px;
      }
    }

    .form-control-custom-input {
      height: 55px !important;
      padding: 15px 10px 0px 40px;
      color: $dark-blue;
      border-radius: 0.3125rem;
      border: 0px;
      font-weight: bold;
      font-size: $text-caption;
      cursor: pointer;
    }
    .without-label {
      padding: 5px 10px 0 40px;
    }

    .booking-label {
      position: absolute;
      top: 10px;
      left: 45px;
      font-size: $text-captio3;
      color: $dark-gray;
      [dir='rtl'] & {
        left: auto;
        right: 45px;
      }
    }

    i {
      position: absolute;
      left: 2px;
      top: 35%;
      margin: 0px 0px 0px 5px;
      font-size: 20px;
      color: $gray-300;
      [dir='rtl'] & {
        left: auto;
        right: 0;
      }
    }
  }
}

.main-tabs {
  padding-top: 1rem;
  .nav-item {
    width: 50%;
    text-align: center;
    .nav-link {
      border: none;
      padding: 0;
      color: $black;
      font-size: $text-body;
    }
    .tab-bar {
      background-color: $gray-100;
      height: 8px;
      margin-top: 0.5rem;
    }
    .nav-link.active {
      font-weight: bold;
      .tab-bar {
        background-color: $green;
      }
    }
  }
  .tab-content {
    padding: 1rem 0;
  }
}

.repeated-week {
  .is-active {
    background-color: $green;
    color: $white;
  }
  > p {
    margin-inline: 0.5rem;
    font-size: 14px;
    padding-top: 5px;
  }
  ul {
    display: flex;
    margin-top: 0.3rem;
    li {
      width: 25px;
      height: 25px;
      line-height: 25px;
      background: #f4f4f4;
      text-align: center;
      border-radius: 50%;
      flex: none;
      margin-inline: 0.2rem;
      cursor: pointer;
      font-size: 14px;
    }
  }
}

.total-price {
  background: $gray-100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 0.3rem;
  padding-inline: 0.5rem;
  border-radius: 5px;
  font-size: $font14;
  color: #646161;
  input {
    // border: 1px solid #cfcfcf7a;
    border: none;
    // background: $gray-100;
    border-radius: 2px;
    direction: rtl;
  }
}

.bg-green-white {
  background: #edf9cb;
}

.pricing-section,
.green-label {
  label {
    color: #98c11d;
  }
}
