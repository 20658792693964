.package-module {
    .title-div {
        width: 30% !important;
    }

    .packageRow {
        border-bottom: 1px solid #C4C4C43D;

        .rowtitle {
            width: 18%;
            text-align: left;
            padding: 10px 0 10px 30px;
            border-right: 1px solid #C4C4C43D;

            &::after {
                content: "";
                height: 2px;

            }
        }

        .values {
            padding-left: 30px;
        }
    }

    .packageFooter {
        button {
            width: 14%;
            border-radius: 8px;
        }

        .btn-delete {
            color: $darkRed;
            border: 2px solid $darkRed;
            background-color: #fff;
        }
    }
}