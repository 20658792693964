.myModule {
    background-color: $gray-100;
    font-family: inherit !important;

    .myContainer {
        padding: 15px 30px 0px 12px;

        .title-div {
            padding-bottom: 8px;
            width: 50%;

            @media (max-width: 900px) {
                width: 100%;
            }

            .Pagetitle {
                padding-bottom: 8px;
                margin-bottom: 10px;
                color: $lime;
                border-bottom: 3px solid $lime;

                [dir='rtl'] & {
                    padding-left: 50px;
                }

                [dir='ltr'] & {
                    padding-right: 50px;
                }
            }

            i {
                padding-right: 40px;
                position: relative;
                top: 10px;

                [dir='rtl'] & {
                    transform: rotateY(180deg);

                }
            }

            button {
                width: 50%;
                height: 38px;
            }

            .stadium-select {
                // right: 13.5rem;
                z-index: 9;
                width: 50%;
                margin-right: 1%;

                [dir='rtl'] & {
                    margin-left: 1%;
                }

                .ant-select-single {
                    color: white;
                    width: 100%;

                    .ant-select-selection-item {
                        font-size: 16px;
                        font-weight: 600 !important;
                    }
                }

                .ant-select-selector {
                    width: 100% !important;
                    height: 37px !important;
                    background: #98c11d !important;
                    color: white !important;
                    font-weight: 600 !important;

                }

            }
        }

        .body {
            background-color: $white;
            border-radius: 30px;
            min-height: 85vh;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            height: 85vh;

            .bodyContent {
                overflow: auto;
                height: 100%;
            }
        }
    }
}