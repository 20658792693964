.addDiscountDiv {
    .fa-circle-xmark {
        position: absolute;
        top: 4%;
        right: 6%;
        font-size: large;

    }

    .selectedItem {
        border: 1.15407px solid #87BE44;
        color: #87BE44;
        background-color: #FBF8F8;

    }

    .DiscountContent {
        .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
            padding: 8px 7px !important;
        }

        .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
            padding: 8px 8px 8px 2px;
        }

        .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
            padding-right: 8px !important;
        }

        .date-range-dayOff {
            .control-field__label {
                display: none !important;
            }

            .control-field {
                margin-left: 2px;
            }

            .control-field__body,
            .control-field {
                border: none !important;
                padding: 0 !important;
            }

            .control-field__body {
                background-color: #FBF8F8 !important;
                text-align: center;

                .date-icon {
                    bottom: -6px;
                }
            }

            .row {
                width: 100%;
                align-items: center;
                display: flex;
                align-items: center;
                background-color: #FBF8F8 !important;

                .SingleDatePickerInput,
                .DateInput_input,
                .DateInput {
                    background-color: #FBF8F8 !important;
                    color: #87BE44;
                    font-weight: 600;

                }

            }

            .control-field__feedback {
                display: none !important;
            }

            .pointer {
                margin: 0 5px;
                color: #807a7a;
            }

        }

        /* Hide the browser's default checkbox */
        .container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .PitchesSpan {
            background-color: #FBF8F8;
            border: 1px solid #DBDBDB;
            border-radius: 17px;
        }

        /* Create a custom checkbox */
        .checkBoxDiv {
            display: flex;
            align-items: center;

            .checkmark {
                border-radius: 5px;
                border: 1px solid #87BE44;

                [dir="ltr"] & {
                    left: 0;
                }

                [dir="rtl"] & {
                    right: 0%;

                }
            }
        }

        .container {
            display: block;
            font-weight: 500;


            :hover input~.checkmark {
                background-color: #ccc;
            }

            input:checked~.checkmark {
                background-color: $green;
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 20px !important;
                width: 20px !important;
                border-radius: 5px !important;
                background-color: #eee;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid gray;
            }

            /* Create the checkmark/indicator (hidden when not checked) */
            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }

            /* Show the checkmark when checked */
            input:checked~.checkmark:after {
                display: block;
            }

            /* Style the checkmark/indicator */
            .checkmark:after {
                left: 9px;
                top: 5px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }

        }

        .createBtn {
            background: #98c11d;
            border: 1px solid #FFFFFF;
            border-radius: 30px;
            color: #FAFAFA;
            width: 140px;
            height: 33px;
        }
    }
}