.text-left {
    [dir='rtl'] & {
        text-align: right !important;
    }
}

.text-right {
    [dir='rtl'] & {
        text-align: left !important;
    }
}

.ml-1 {

    [dir='rtl'] & {
        margin-right: 0.25rem !important;
        margin-left: 0 !important;
    }
}

.mr-1 {

    [dir='rtl'] & {
        margin-left: 0.25rem !important;
        margin-right: 0 !important;
    }
}

.ml-2 {
   

    [dir='rtl'] & {
        margin-right: 0.5rem !important;
        margin-left: 0 !important;

    }
}

.mr-2 {
    [dir='rtl'] & {
        margin-left: 0.5rem !important;
        margin-right: 0 !important;

    }
}

.ml-3 {

    [dir='rtl'] & {
        margin-right: 1rem !important;
        margin-left: 0 !important;

    }
}

.mr-3 {
 
    [dir='rtl'] & {
        margin-left: 1rem !important;
        margin-right: 0 !important;

    }
}

.ml-4 {
   [dir='rtl'] & {
        margin-right: 1.5rem !important;
        margin-left: 0 !important;

    }
}

.mr-4 {
    [dir='rtl'] & {
        margin-left: 1.5rem !important;
        margin-right: 0 !important;
    }
}

.ml-5 {
   [dir='rtl'] & {
        margin-right: 3rem !important;
        margin-left: 0 !important;

    }
}

.mr-5 {
   
    [dir='rtl'] & {
        margin-left: 3rem !important;
        margin-right: 0 !important;

    }
}

.pl-1 {

    [dir='rtl'] & {
        padding-right: 0.25rem !important;
        padding-left: 0 !important;
    }
}

.pr-1 {

    [dir='rtl'] & {
        padding-left: 0.25rem !important;
        padding-right: 0 !important;
    }
}

.pl-2 {
   

    [dir='rtl'] & {
        padding-right: 0.5rem !important;
        padding-left: 0 !important;

    }
}

.pr-2 {
    [dir='rtl'] & {
        padding-left: 0.5rem !important;
        padding-right: 0 !important;

    }
}

.pl-3 {

    [dir='rtl'] & {
        padding-right: 1rem !important;
        padding-left: 0 !important;

    }
}

.pr-3 {
 
    [dir='rtl'] & {
        padding-left: 1rem !important;
        padding-right: 0 !important;

    }
}

.pl-4 {
   [dir='rtl'] & {
        padding-right: 1.5rem !important;
        padding-left: 0 !important;

    }
}

.pr-4 {
    [dir='rtl'] & {
        padding-left: 1.5rem !important;
        padding-right: 0 !important;
    }
}

.pl-5 {
   [dir='rtl'] & {
        padding-right: 3rem !important;
        padding-left: 0 !important;

    }
}

.pr-5 {
   
    [dir='rtl'] & {
        padding-left: 3rem !important;
        padding-right: 0 !important;

    }
}
