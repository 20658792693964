// Colors
$black: #000000;
$white: #ffffff;
$gray-100: #f4f4f4;
$gray-150: #e8e8e8;
$gray-200: #eae8e9;
$gray-250: #c8c7c7;
$gray-280: #C4C4C43D;
$gray-500:rgba(149, 149, 155, 1);
$gray-300: #acacac;
$gray-400: #dee1e3;
$dark-gray: #4F504E;
$gray: #454545;
$dark-blue: #324356;
$blue: #0184fe;
$red: #ff5f59;
$orange: #f28f08;
$orangColor: #dc7542;
$green-100: #00b43740;
$green: #98c11d;
$lime: #88BE46;
$Newgreen: #1EA66D;
$lightGreen: #87BE4433;
$goldColor: gold;
$blueBlack: #354f5c;
$whiteBlue: #edf2f7;
$darkRed:#A61E1E;
// Font Sizes
$text-title: 1.5rem;
$text-title2: 1.3rem;
$text-title3: 1.25rem;
$text-title4: 1.56rem;
$text-title5: 1.12rem;
$text-subtitle: 1.12rem;
$text-headline: 1rem;
$text-body: 0.8rem;
$text-caption: 0.75rem;
$text-captio2: 0.625rem;
$text-captio3: 0.68rem;
$font14: 0.875rem;
$font13: 0.81rem;
$font10: 0.625rem;