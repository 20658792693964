.modalDeleteAddon {
    .modal-content {
        width: 90%;
    }

    .modal-body {
        padding: 40px;

        .titleModal {
            font-size: $text-title3;
            color: #54595E;
            font-weight: 600;
        }

        .cancel {
            color: $darkRed;
            border: 2px solid $darkRed;
            font-size: $text-body;
            background-color: transparent;
            border-radius: 8px;
            width: 180px;
            padding: 12px 0;
        }

        .delete {
            color: $lime;
            border: 2px solid $lime;
            font-size: $text-body;
            background-color: transparent;
            border-radius: 8px;
            width: 180px;
            padding: 12px 0;

        }
    }
}