.copounModule {
    .couponTitle {


        h3 {
            margin-left: 5px;
            color: #87BE44;
            border-bottom: 3px #87BE44 solid;
            padding: 3px 2px;
            font-weight: 600;
            font-size: 24px;
            line-height: 24px;
        }
    }

    .leftSide,
    .rightSide {
        background: #FAFAFA;
        height: 90vh;
        z-index: 0 !important;

        .titles {
            h4 {
                color: #5a5757;

            }

            .createbtn {

                background: #87BE44;
                border: 1px solid #FFFFFF;
                border-radius: 30px;
                color: #FAFAFA;
                width: 140px;
                height: 33px;
            }
        }

        .coupon-content {
            overflow: auto;
            height: 80vh;
        }


        .couponDiv {
            background: linear-gradient(180deg, #FFFDFD 0%, #ECECEC 78.65%, #DBDBDB 100%);
            border: 1px solid #87BE44;
            border-radius: 10px;
            min-height: 145px;
            position: relative;


            .discount-table th,
            td {
                border: 1px solid black;
                border-collapse: collapse;
                text-align: center;
            }

            table {
                border-radius: 0px 0px 13.2018px 13.2018px;

            }
            .activeSpan {
                color: #1EA66D;
                background: #FFFFFF;
                border: 1.32018px solid #DBDBDB;
                border-radius: 19.8026px;
                position: absolute;
                top: -0.67982px;
                padding: 0 8px;

                [dir='ltr'] & {
                    right: -0.67982px;
                }

                [dir='rtl'] & {
                    left: -0.67982px;
                }

            }
        }



    }

}