.AddModalBody {
    background-color: #fff !important;
    .addAddOnForm {
        .control-field {
            border-radius: 8px;
            border: transparent;

            .control-field__body {
                border-radius: 8px;
                border: 1px solid var(--black-1, #CFD3D4);

                input,
                .control-field__select {
                    padding-top: 7px;
                    color: #5a5c5d;
                }

                label.control-field__label {
                    font-size: 12px;
                    color: #5E6366;
                    font-weight: 400;
                }
            }

        }

    }
}