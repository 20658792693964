.pitchesSection {
    min-height: 100px;

    .addNewPitch {
        height: 40px;
        width: 170px;

    }

    .dayBox {
        border-radius: 5px;
        border: 1px solid #C4C4C4;
        font-weight: 400;
        cursor: pointer;

        &.selectedDate {
            background-color: #87BE44;
            color: #fff;

        }
    }

    .myPitches.titleDiv {
        min-height: 35px;

        .pitchName {
            .title {
                text-decoration: underline;
                font-weight: 700;
            }

            span {
                text-decoration: underline;

            }

        }

        .btnsDiv {
            button {
                font-size: 10px;
                height: 30px;
                width: 80px;
                border-radius: 3px;
                border-width: 2px;

                &.btn-delete {
                    background-color: $darkRed;
                    color: #fff;
                }
            }
        }
    }

    .prices {


        .control-field {
            label.control-field__label {
                font-size: $text-caption;
                color: #5E6366;
                font-size: 0.75rem;
            }
        }

    }
}