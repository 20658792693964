.rewardsTable {

    .rewardsThead {
        background-color: $dark-gray;

        tr th {
            color: var(--Light, #FFF);
            font-size: 12px;
            font-weight: 400;
            border-radius: 0 !important;

        }

    }

    tbody tr td {
        font-size: 12px;
        color: var(--dark-tone-100, #242533);
        font-weight: 400;

    }

    .rewardFooter {
        background-color: #E1FFBD;
        border-radius: 0px 0px 5px 5px !important;
        border-bottom: 20px solid white;

        button {
            border-radius: 5px;
            background-color: #fff;
            height: 29px;
            padding: 6px 15px;
            line-height: normal;

            &.deactivate {
                border: 1px solid #BE4646;
                width: 195px;
                color: #BE4646;

            }

            &.usage {
                color: #87BE44;
                border: 1px solid #87BE44;
                width: 145px;

            }
        }
    }
}