.AvaiabilityPage {

    .availabiliy_section {
        width: 80%;

        .checkmark {
            border-color: $Newgreen;
        }

        label {
            color: #2B2F32 !important;
            font-weight: 400;

            [dir='rtl'] & {
                direction: ltr;

            }

            [dir='ltr'] & {
                direction: rtl;

            }

            .MuiTypography-root {
                font-size: small;

                [dir='rtl'] & {
                    padding-left: 10px;
                }

                [dir='ltr'] & {
                    padding-right: 10px;

                }

            }
        }

        .timePicker {
            button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium {
                margin-bottom: 20px;
            }

            label {
                color: #5E6366 !important;
                font-weight: 400;
                font-size: 10px !important;

            }
        }

        .selectedDayRow {
            border-bottom: 2px solid var(--black-1, #CFD3D4);
            margin-bottom: 25px;

            .checkBoxDiv {
                background-color: $lightGreen;

                @media only screen and (max-width: 900px) {
                    label {
                        padding-bottom: 20px !important;

                    }
                }
            }
        }

    }

    .day {
        margin-left: 38px;
    }

    .timeInputsDiv {
        padding-left: 50px;

        @media only screen and (max-width: 900px) {
            padding: 0;
        }

        .timePicker {
            padding-right: 35px;

            @media only screen and (max-width: 900px) {
                padding: 0;
            }
        }
    }

}