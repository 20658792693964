.addons-box {
  .img-box {
    border: 1px solid black;
    border-radius: 5px;
    text-align: center;
    height: 200px;
    padding: 15px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .counter-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $gray-100;
    margin-top: 10px;
    padding: 8px;
    border-radius: 5px;
    .counter-state {
      font-weight: bold;
      font-size: 20px;
      color: $green;
    }
    .counter-icon {
      background: $green;
      width: 25px;
      height: 25px;
      line-height: 27px;
      text-align: center;
      border-radius: 50%;
      color: $white;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .addon-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    margin-top: 5px;
  }
}
