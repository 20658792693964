.Amount-due {
    background-color: #63A6E3;
    color: #fff;

    .AmountDiv {
        background-color: #fff;
        color: #4F504E;
        line-height: initial;
        border-radius: 3%;

        .amountPar {
            font-weight: 600;
            font-size: 0.8rem;
        }

        .amount {
            font-size: 1.8rem;
        }
    }

    .breakDowns {
        width: 34%;
        font-weight: 400;
        font-size: small;

        h6 {
            line-height: 21px;
            text-decoration-line: underline;
        }

        .table-item {
            border-top: 0.751908px solid #FFFFFF;

            &:first-of-type {
                border-top: 0;
            }

            .numberSpan {
                display: inline-block;
                width: 70px;
                text-align: left;
            }
        }

    }

}

table.table {
    margin-bottom: 50px;
    border-bottom: 1px solid #D9D9D9;
    width: 100%;
    font-size: medium;

    tbody {
        tr {

            td,
            th {
                color: #4F504E;
                font-weight: 600;

            }
        }

        tr.pendingTr {
            background-color: #63A6E3;
            height: auto;

            td,
            th {
                color: #fff;
            }

            .statusPending {
                color: #0C3FC0 !important;
            }
        }
    }

    .rewardsThead {

        tr:first-child th:first-child {
            border-radius: 10px 0 0 0 !important;

            [dir='rtl'] & {
                border-radius: 0px 10px 0 0 !important;

            }

        }

        tr:first-child th:last-child {
            border-radius: 0px 10px 0 0 !important;

            [dir='rtl'] & {
                border-radius: 10px 0 0 0 !important;

            }
        }
    }

    thead {
        background-color: #D9D9D9;

        tr th {
            color: #272826;
            border-top: none;
            font-weight: 900;
            font-size: 1.1rem;
            line-height: 20px;
           
        }

    }

    .statusPending {
        color: #0C3FC0;

        .circle {
            border-radius: 50%;
            width: 16px;
            height: 16px;
            border: 1.2px solid #0C3FC0;
            display: inline-block;

            i {
                font-size: 0.8rem;
            }
        }
    }

    .statusRecieved {
        color: #00A42E;
    }
}

.pagesNav {


    .page-link {
        color: #98c11d;
        font-weight: 500;
    }

    li.active .page-link {
        background-color: #98c11d !important;
        color: #fff !important;
    }
}