.pitchModal {

    .addPitchDiv {
        min-height: 655px;
        height: fit-content !important;

        input {
            font-size: 12px;
        }


        select {
            font-size: 12px;

            option {
                font-size: 12px;

                &:hover {
                    background-color: #98c11dab;
                    color: #fff;
                }
            }

        }

        .inputwithLabel {
            padding: 6px 0;
        }

        .selectedDate {
            background-color: $lime;
            color: #fff;
            border-radius: 5px;
        }

        .InputsDiv {
            .icon {
                display: none !important;
            }

            .inputwithLabel {
                width: 100% !important;

                .control-field__label {
                    display: block;
                }
            }
        }

        td {
            vertical-align: middle !important;

            i {
                font-size: larger;
            }

            .icon {
                display: none !important;
            }

            .inputwithLabel {
                width: 100% !important;

                .control-field__label {
                    display: block;
                }
            }

            .control-field__body {
                background-color: #FBF8F8 !important;
                padding: 2px 5px;
            }

            .css-i4bv87-MuiSvgIcon-root {
                width: 0.8em !important;
            }
        }

        .fa-rectangle-xmark {
            position: absolute;
            top: 4%;
            font-size: large;

            [dir='ltr'] & {
                right: 5%;

            }

            [dir='rtl'] & {
                left: 5%;

            }
        }




        /* Hide the browser's default checkbox */
        .mycontainer input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        /* Create a custom checkbox */
        .checkBoxDiv {
            position: relative;
        }

        .mycontainer {
            display: block;
            font-weight: 500;


            :hover input~.checkmark {
                background-color: #ccc;
            }

            input:checked~.checkmark {
                background-color: $green;
            }

            .checkmark {
                position: absolute;
                top: 0;
                height: 20px !important;
                width: 20px !important;
                border-radius: 5px !important;
                background-color: #eee;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid gray;

                [dir='rtl'] & {
                    right: 18% !important;
                }

                [dir='ltr'] & {
                    left: 15% !important;
                }
            }

            /* Create the checkmark/indicator (hidden when not checked) */
            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }

            /* Show the checkmark when checked */
            input:checked~.checkmark:after {
                display: block;
            }

            /* Style the checkmark/indicator */
            .checkmark:after {
                left: 8px;
                top: 3px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }

        }

        td>.checkBoxDiv {
            display: flex;
            justify-content: center;

            .mycontainer {
                position: relative !important;
                width: fit-content;

                .checkmark {
                    position: relative !important;
                    right: 0 !important;
                }
            }
        }



        th {
            font-size: 0.9rem;
            vertical-align: middle;
        }

    }

}