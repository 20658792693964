.app-login {
  background: url(../../../assets/images/bg-login.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 8rem;
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #00000026;
  }

  .login-box {
    position: relative;

    .logo {
      text-align: center;
      margin-bottom: 2.5rem;
    }

    .login-content {
      background: white;
      padding: 1.5rem;
      border-radius: 5px;
      min-width: 363px;

      label {
        display: none;
      }
      .control-field__input {
        font-size: 12px !important;
      }

      .control-field {
        padding: 0.25rem !important;
      }
    }
  }
}