.addonsModule {

    &.modal-dialog {
        @media (min-width: 576px) {
            max-width: 700px !important;
        }
    }

    .modal-content {
        min-height: 85vh;

        .modal-header {
            border: none;

            .modal-title {
                width: 85%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 20px;

                .createAddonBtn {
                    width: 91%;
                    bottom: 10px;
                    right: 38px;
                }

                .title {
                    font-weight: 700;
                    font-size: $text-title2;
                    color: $dark-gray;
                }
            }

            .fa-circle-arrow-left {
                left: 3.5%
            }
        }
    }

    .modal-body {
        background-color: #F0F4F5;
        max-height: 450px;
        overflow: auto;

    }
}