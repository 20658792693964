body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'proxa';

  [dir='rtl'] & {
    font-family: "Cairo";
  }
}


/* classes*/
.cursor-pointer {
  cursor: pointer !important;
}

@font-face {
  font-family: "proxa";
  src: local("proxa"),
    url("./assets/fonts/Proxima.otf") format("truetype");
}