.app-reset {
    background: url(../../../assets/images/bg-reset.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.app-success {
    background: url(../../../assets/images/bg-successfully.png);
    background-repeat: no-repeat;
    background-size: cover;
}