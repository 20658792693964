.btn-lime-100 {
    background-color: $lime;
    color: $white;
    border-radius: 8px;
    font-weight: 700;
    width: 180px;
}

.btn-lime-reverse {
    background-color: $white;
    border: 3px solid #87BE44;
    color: #87BE44;
    border-radius: 8px;
    font-weight: 700;
    width: 180px;
}

.btn-delete {
    background-color: $white;
    border: 3px solid $darkRed;
    color: $darkRed;
    border-radius: 8px;
    font-weight: 700;
    width: 180px;
}

.coachesList {

    .coachItem,
    .packageItem {
        border-radius: 10px;
        border: 1px solid $lime;

        img {
            border-radius: 10px;
        }

        .delete {
            right: 12px;
            top: 12px;
        }

        .coachItem-footer {
            background-color: $gray-280;
        }


    }
}