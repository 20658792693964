.daysOff {
    min-height: 350px;

    .addDayOffBtn {
        height: 40px;
        width: 170px;
    }

    .daysOfftable {
        [dir='ltr'] & {
            padding-left: 5px;

        }

        [dir='rtl'] & {
            padding-right: 5px;

        }

        thead>tr>th {
            background-color: $dark-gray;
            color: #fff;
            font-weight: 500;
            padding: 5px 0 3px 0;
            font-size: 0.75rem;
            line-height: 12px;

            &:first-of-type {
                [dir='ltr'] & {
                    padding-left: 5px;

                }

                [dir='rtl'] & {
                    padding-right: 5px;

                }
            }
        }

        tbody tr {
            background-color: rgba(207, 211, 212, 0.4);

            td {
                border-bottom: 1px solid #fff;
                color: #000000;
                font-size: 0.75rem;
                line-height: 12px;
                padding: 5px 0;

                &:first-of-type {
                    [dir='ltr'] & {
                        padding-left: 5px;

                    }

                    [dir='rtl'] & {
                        padding-right: 5px;

                    }
                }
            }
        }
    }

    .addDayOff {
        position: relative;

        &::before {
            content: "";
            display: inline-block;
            min-height: 320px;
            width: 1px;
            background-color: #95959B;
            position: absolute;
            top: 0%;
        }

        .dateField {
            width: 45%;

            .control-field {
                height: 48px;
                border-radius: 5px;

                .control-field__label {
                    text-transform: none !important;
                    font-weight: 400;
                    font-size: 0.75rem;
                    color: #5E6366;
                }
            }
        }

        .pitchSelection {
            .pitchToSelect {
                min-height: 29px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                background-color: #fbf8f8;
                border: 0.8px solid #DBDBDB;
                font-size: 0.68rem;
                font-weight: 600;
                color: #535353;
                text-align: center;

                &.selectedDate {
                    background-color: #87BE44;
                    color: #fff;

                }
            }
        }
    }
}

.creatingDaysOff {
    .activePitch {

        background-color: #1EA66D !important;
        color: #fff !important;

    }

}