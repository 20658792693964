.repeat-weekly {
  font-size: $text-caption !important;
  color: $green;
  padding-top: 0.3rem;
  font-weight: normal !important;
}

.booking-box {
  background: #f3f3f3;
  padding: 0 0.5rem;
  border-radius: 5px;
  margin-bottom: 0.5rem;

  p {
    font-size: 14px;
    font-weight: 600;
  }

  .booking-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #c4c4c4;
    padding: 0.5rem 0;

    &:last-child {
      border: none;
    }
  }

}

.booking_note {
  background-color: #F8FFCC;
  padding: 10px 10px;
  border-radius: 5px;
  border: 1px solid #EED704;
  height: 75px;
}

.price {
  p {
    font-size: 20px;
  }
}

.view-booking {
  .modal-footer {
    button {
      width: 100%;
      padding-block: 0.5rem;
      font-size: $font14;
    }

    .cancel-booking {
      background: $white;
      color: $red;
      border-color: $red;
      box-shadow: none;
    }
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkBoxDiv {
    position: relative;
  }

  .container {
    display: block;
    font-weight: 500;


    :hover input~.checkmark {
      background-color: #ccc;
    }

    input:checked~.checkmark {
      background-color: $green;
    }

    .checkmark {
      position: absolute;
      top: -2px;
      left: 60px;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      background-color: #eee;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid gray;

      [dir='rtl'] & {
        left: -33px;

      }
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    input:checked~.checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

  }

}

.print-button {
  display: flex;
  flex-direction: row-reverse;
  padding: 1rem;

  button {
    border: 1px solid #d9d9d9;
    padding-inline: 2rem;
    padding-block: 0.2rem;
    background: white;
    font-size: 14px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: $gray-100;
    }
  }
}




.printReciept {
  top: 0;
  left: 0;
  right: 0;
  left: 0 !important;
  z-index: 9999;
  background-color: rgba(204, 204, 204, 0.474);
  align-items: center;
  justify-content: center;

  .printRecieptUrl {
    width: 100%;
    height: 100%;
  }

  .close {
    position: absolute;
    top: 9%;
    left: 15px;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgb(24, 23, 23);

    .closeIcon {
      color: #fff;
      font-size: 1.2rem;
    }
  }
}







@media print {

  nav,
  .modal-footer,
  .calendar-module,
  .print-button {
    display: none !important;
  }
}

.font-12 {
  font-size: 12px !important;
}