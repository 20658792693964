.DeletePitchComponent {
    background-color: rgba(217, 217, 217, 0.8);
    overflow: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    min-height: 100vh;
    z-index: 9;


    .deletePitchDiv {
        min-height: 200px;
        height: fit-content !important;
        border-radius: 15px;
        border: #A61E1E 3px solid;

        h3 {
            color: #A61E1E;
        }

        button {
            border-radius: 12px;
            color: #fff;
        }

        .green {
            background-color: #87BE44;
        }

        .deleteBtn {
            background-color: #A61E1E;
            border-color: #6f0e0e;

            &:hover {
                box-shadow: 0 0.125rem 0.25rem #A61E1E;
            }
        }
    }
}