.actionLogs {
    padding: 1rem;

    .clrDiv {
        clear: both;
    }

    .bookingLog {
        border: 1px solid #dee2e6;
        padding: 1rem;

        .headersOfLogs {
            .bookingCreationType {
                text-decoration-line: underline;

            }
        }

        .dateOfEdit {
            float: right;
        }

        .divDetails {
            .default {
                display: block;

                [dir='ltr'] & {
                    float: right;
                }

                [dir='rtl'] & {
                    float: left;

                }

                .arrow {
                    transition: all 1ms;
                }
            }



            .BookingLogsDetails {
                transition: all 1ms;
            }
        }


    }
}