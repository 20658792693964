.confirmation-overlay.ReactModal__Overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	background-color: rgba(0, 0, 0, 0.6);
}

.confirmation-overlay.ReactModal__Overlay--after-open {
	opacity: 1;
	transition: opacity 300ms ease-out;
}

.confirmation-modal.ReactModal__Content {
	position: absolute;
	top: 50%;
	left: 50%;
	right: auto;
	bottom: auto;
	border: 1px solid #eee;
	margin-right: -50%;
	transform: scale(0);
	background-color: #fff;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	outline: none;
	padding: 20px;
	opacity: 0;
}

.confirmation-modal.ReactModal__Content--after-open {
	opacity: 1;
	transform: translate(-50%, -50%) scale(1);
	transition: all 300ms ease-out;
}

.confirmation-modal button {
	border: 1px solid black;
	background-color: #fff;
	color: #333;
	padding: 4px 8px;
	margin: 4px;
	border-radius: 3px;
	cursor: pointer;
}

.confirmation-modal button:hover {
	background-color: #eee;
}
