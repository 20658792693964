.bookingVideosDiv,
.otherVideosDiv {
    overflow: auto;

    .bookingElemet {
        border-radius: 30px;
        border-bottom: 1px solid #B3B3B3;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px #DAE0E6;

        .bookingNumber {
            border-radius: 30px 30px 0 0;
            height: 28px;
            background-color: rgba(179, 179, 179, 0.24);
        }

        .bookingInfo {
            * {
                line-height: 1rem;
            }

            .dateInfo {
                [dir='ltr'] & {
                    border-right: 2px solid $black;
                }

                [dir='rtl'] & {
                    border-left: 2px solid $black;

                }
            }

            .btn-default-opacity {
                height: 38px;
                width: 250px;
            }
        }
    }
}