.banner {
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    border: 1px solid #f5c6cb;
    font-size: 13px;
  }
  .banner a {
    color: #0056b3;
    text-decoration: underline;
    margin-left: 5px;
    font-size: 16px;
  }
  