/* ----------------------------------------------------------------------------------------------------------------------------------------------------
    01. Basic Styles
---------------------------------------------------------------------------------------------------------------------------------------------------- */
:root {
  font-size: 14px !important;
  background-color: $gray-100;
}

/* ----------------------------------------------------------------------------------------------------------------------------------------------------
    02. Border Radius ( 10 : 100 )
---------------------------------------------------------------------------------------------------------------------------------------------------- */
@for $i from 1 through 10 {
  .radius-#{$i*10} {
    border-radius: #{$i * 10}px !important;
  }
}

/* ----------------------------------------------------------------------------------------------------------------------------------------------------
    03. Opacity ( 10 : 100 )
---------------------------------------------------------------------------------------------------------------------------------------------------- */
@for $i from 1 through 10 {
  .opacity-#{$i*10} {
    opacity: #{$i/10} !important;
  }
}

/* ----------------------------------------------------------------------------------------------------------------------------------------------------
    04. Margins
---------------------------------------------------------------------------------------------------------------------------------------------------- */
@for $i from 1 through 10 {
  .mt-#{$i*10} {
    margin-top: #{$i * 10}px !important;
  }
}

@for $i from 1 through 10 {
  .mb-#{$i*10} {
    margin-bottom: #{$i * 10}px !important;
  }
}

/* ----------------------------------------------------------------------------------------------------------------------------------------------------
    05. General Styles
---------------------------------------------------------------------------------------------------------------------------------------------------- */
// Add your general styles here...
.navbar {
  z-index: 9;
}

.form-control:focus {
  box-shadow: none;
}

select {
  cursor: pointer;
}

.dropdown-menu {
  min-width: auto;
}

/*navbar*/

.btn-clear {
  border: 0;
  background: none;
}

.pointer {
  cursor: pointer !important;
}

//Custom-Col
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-right: 5px;
  padding-left: 5px;
}

.container-fluid {
  padding-right: 10px;
  padding-left: 20%;
  padding-block: 15px;

  [dir='rtl'] & {
    padding-right: 20%;
    padding-left: 15px;
  }
}

.form-group {
  margin-bottom: 0rem !important;
}

.w-30 {
  width: 30%;
}

.disable {
  background-color: gray !important;
}



.v-divider {
  border: 1px solid #c8c7c7;
}

.isDisabled {
  background: #f4f4f4;

  .css-1fhf3k1-control {
    background: #f4f4f4 !important;
  }

  input[type='text']:disabled {
    background: #f4f4f4;
    cursor: cross;
  }

  input::placeholder {
    color: black;
    opacity: 1;
    font-weight: bold;
  }

  input:disabled {
    &::placeholder {
      font-weight: 100 !important;
      color: #9c9c9c !important;
    }
  }

  input:-ms-input-placeholder {
    color: black;
  }

  input::-ms-input-placeholder {
    color: black;
  }
}

input:disabled {
  background: #f4f4f4;
  cursor: not-allowed !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

//fadeIn Tabs
.tab-pane.active {
  animation-name: tabFade;
  animation-duration: 500ms;
  animation-timing-function: linear;
}

// FadeIn Tabs
@keyframes tabFade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

html[dir='rtl'] {

  // Handling Icon Plane
  body {
    direction: rtl;
  }
}

.vh-35 {
  min-height: 35vh;
}

.btn-light:focus,
.btn-light.focus {
  box-shadow: none;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: none;
}

.DateInput {
  width: auto !important;
  max-width: 130px !important;
}

.DateRangePickerInput,
.SingleDatePickerInput {
  height: 18px;
  overflow: hidden;
  border: 0 !important;
  display: flex !important;

  .DateInput_input__focused {
    border-bottom: 0 !important;
  }

  .DateInput_input {
    font-size: $text-caption !important;
    padding: 0 !important;
    line-height: 0 !important;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: $blue !important;
    border: 1px double $blue !important;
  }

  .CalendarDay__selected_span,
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    color: white !important;
    background: rgba(1, 123, 254, 0.7) !important;
    border: 1px double rgba(1, 123, 254, 0.7) !important;
  }

  .DateInput_input__disabled {
    font-style: inherit !important;
  }

  .DateRangePickerInput_arrow_svg {
    display: none;
  }
}

.DateRangePicker_picker,
.SingleDatePicker_picker {
  top: 41px !important;
  left: -10px !important;

  [dir='rtl'] & {
    left: auto;
    right: -10px !important;
  }
}

.CalendarMonthGrid__horizontal {
  [dir='ltr'] & {
    transform: translateX(0px);
    left: 9px;
  }

  [dir='rtl'] & {
    transform: translateX(292px) !important;
    right: 0px !important;
    left: 0% !important;

  }
}

.DateInput_fang {
  top: 31px !important;

  [dir='rtl'] & {
    left: auto !important;
    right: 22px;
  }
}

input {
  cursor: pointer !important;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}


.roundedButton {
  border-radius: 50%;
  width: 32px;
  height: 32px;

  i:not(.fa-trash) {
    vertical-align: bottom;
  }

  i {
    vertical-align: baseline;
    margin-left: -1px;
    line-height: initial;
  }
}

.discountAdjust {
  height: 48px;
  display: flex;
  align-items: center;
}

.ml-auto {
  [dir='rtl'] & {
    margin-left: initial !important;
    margin-right: auto !important;
  }
}

input {
  cursor: pointer !important;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}



.ml-auto {
  [dir='rtl'] & {
    margin-left: initial !important;
    margin-right: auto !important;
  }
}

.green-color {
  color: $green !important;
}

//Handle Chechbox RTL
.custom-control {
  [dir='rtl'] & {
    padding-left: initial;
    padding-right: 1.5rem;
  }
}

.disabled {
  background-color: #eae8e9 !important;
  cursor: no-drop !important;

  li {
    cursor: no-drop !important;
  }
}

.btn-default {
  background-color: $green;
  border-color: $green;
  color: $white;

  &:hover {
    color: $white;
    box-shadow: 0 0.125rem 0.25rem $green;
  }

  &:focus {
    box-shadow: 0 0.125rem 0.25rem $green;
  }
}

.btn-disabled {
  background-color: $gray-300;
  border-color: $gray-300;
  color: $white;
}

.btn-default-opacity {
  background-color: $lime;
  border-color: $lime;
  color: $white;

  &:hover {
    color: $white;
    box-shadow: 0 0.125rem 0.25rem $lime;
  }

  &:focus {
    box-shadow: 0 0.125rem 0.25rem $lime;
  }
}

.text-default {
  color: $green;
}

//Toggle checkbox
.switch-checkbox {
  display: inline-block;
  height: 28px;
  position: relative;
  width: 60px;
}

.switch-checkbox input {
  display: none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: #fff;
  bottom: 1px;
  content: '';
  height: 25px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 25px;
}

input:checked+.slider {
  background-color: #85c240;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* ===== Scrollbar CSS ===== */

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #8a8a8a;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.home-section {
  min-height: 100vh;
}

.no-gutters {
  >[class*='col-'] {
    background: #f4f4f4;
  }

  .control-field__select {
    width: 100%;
    background: transparent;

    &:last-child {
      [dir='rtl'] & {
        text-align: right;
      }

      [dir='ltr'] & {
        text-align: left;

      }
    }

    div[class$='-control'] {
      max-height: 18px;
      border: 0px !important;
      min-height: 18px !important;
      box-shadow: 0 0 0 0 transparent !important;
      background: transparent;
      cursor: pointer;

      div[class$='-1hwfws3'] {
        min-height: 18px !important;
        padding: 0 !important;
        position: unset !important;
      }

      .css-1wy0on6 {
        display: unset !important;
      }

      div[class$='-ValueContainer'] {
        max-height: 18px;
        position: unset !important;
        padding: 0 !important;

        .css-1wa3eu0-placeholder {
          margin: 0 !important;
        }
      }

      .css-1okebmr-indicatorSeparator {
        display: none;
      }

      div[class$='-indicatorContainer'] {
        padding: 0 !important;
      }
    }
  }
}

.isDisabled {
  background: #f4f4f4;

  .css-1fhf3k1-control {
    background: #f4f4f4 !important;
  }

  input[type='text']:disabled {
    background: #f4f4f4;
  }

  input::placeholder {
    color: black;
    opacity: 1;
    font-weight: bold;
  }

  input:disabled {
    &::placeholder {
      font-weight: 100 !important;
      color: #9c9c9c !important;
    }
  }

  input:-ms-input-placeholder {
    color: black;
  }

  input::-ms-input-placeholder {
    color: black;
  }
}

.phone-input {
  select {
    appearance: auto;
    max-width: 87px;
    border-radius: 0;
    margin-right: 3px;

    [dir="rtl"] & {
      margin-right: initial;
      margin-left: 3px;
    }
  }
}

.modal-header {
  .close {
    [dir="rtl"] & {
      margin: -1rem auto -1rem -1rem;
    }
  }
}

.w-48 {
  width: 48% !important;
}

.modal-sm {
  max-width: 350px !important;
}

.pagination {
  padding-top: 10px;
}

@-ms-viewport {
  body {
    max-zoom: 0.8 !important;
    width: device-width !important;
  }
}

.stadium-select {
  .basic-single {
    [class*="control"] {
      border-color: $green !important;

      [class*="singleValue"] {
        color: $green !important;
      }
    }
  }
}

.font-proxa {
  font-family: 'proxa';

  [dir='rtl'] & {
    font-family: "Cairo";
  }
}