.malaebLive-module {
    .pitch-select {
        select {
            background: $white;
            color: $green;
            border: 1px solid $green;
            height: 36px;
            font-size: 16px;
            font-weight: 600;
            border-radius: 5px;
            width: 100%;
        }

    }

    .fa-circle-arrow-left {
        top: 20px !important;

        [dir='rtl'] & {
            right: -6.5%;
            transform: rotateY(180deg);
        }

        [dir='ltr'] & {
            left: -2.5%;

        }
    }

    .react-datepicker__input-container {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        .datePicker {
            background: transparent;
            color: $green;
            border: 1px solid $green;
            font-size: 16px;
            font-weight: 600;
            border-radius: 5px;
            width: 100% !important;
            z-index: 99;

            &::placeholder {
                color: $green !important;
            }
        }
    }

    .react-datepicker__calendar-icon {
        font-size: 0.6rem;
    }

    .react-datepicker__view-calendar-icon input {
        padding: 6px 10px 4px 25px !important;
    }

    .col-md-4 {
        @media only screen and (min-width: 700px) and (max-width: 1024px) {
            zoom: 80%;
        }
    }
}