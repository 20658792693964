nav {
  height: 100vh;
  position: fixed;
  z-index: 9;
  top: 0;
  left: 10px;
  background: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 17%;
  font-family: "proxa";
  border-right: 2px solid #EAECEE;
  box-shadow: 0px 0px 12px #d8d8d8;
  overflow: auto;

  [dir='rtl'] & {
    right: 10px;
    left: inherit;
    border-left: 2px solid #EAECEE;
    font-family: 'Noto Sans Arabic', sans-serif !important;
  }

  .languageSelection {
    .lang-dropdown {
      .languageSwitcher {
        background-color: #fff;
        border: 1px solid $green;
        padding: 0.5rem 0.3rem;
        border-radius: 5px;
        appearance: none;
        color: $green;
        width: 100px;
        text-align: center;
        font-weight: 900;

        [dir='rtl'] & {
          font-family: 'Noto Sans Arabic', sans-serif !important;
        }

        &::after {
          display: none;
        }
      }

      .dropdown-item {
        display: flex;
        justify-content: center;
        width: 100px;
        padding: 0;
      }
    }

  }

  .user-img {
    border: 1px solid #228335;
    border-radius: 50%;
    background: white;
    width: 80px;
    height: 80px;
  }

  .navText {
    color: rgba(149, 149, 155, 1);
    font-family: "proxa" !important;

  }

  .listOfItems {
    .list-item {
      border-radius: 5px;
      cursor: pointer;

      .iconDiv {
        background-color: #efeff0;
        width: 33px;
        height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        .iconImg {
          width: 20px;
        }
      }

      .list-item-text {
        margin-left: 25px;
        font-family: "proxa" !important;
        font-weight: 400;

        [dir='rtl'] & {
          margin-right: 25px;
          font-family: 'Noto Sans Arabic', sans-serif !important;
          font-size: 0.9rem !important;
          font-weight: 600;
        }
      }

      &:hover {

        background-color: #EDFADE;

        .iconDiv {
          background-color: #def1c7;

          .iconImg {
            filter: grayscale(108%) brightness(89%) sepia(74%) hue-rotate(60deg) saturate(400%);
          }
        }

        .list-item-text {
          color: $lime;
        }
      }
    }

    .active-list-item {
      background-color: #EDFADE;

      .iconDiv {
        background-color: #def1c7;

        .iconImg {
          filter: grayscale(108%) brightness(89%) sepia(74%) hue-rotate(60deg) saturate(400%);
        }
      }

      .list-item-text {
        color: $lime;
      }
    }
  }

  .logout {
    cursor: pointer;

    .iconImg {
      width: 20px;
      margin-left: 20px;

      [dir='rtl'] & {
        margin-right: 20px;

      }
    }

    .list-item-text {
      color: $darkRed;
      margin-left: 12px;
      font-weight: 600;
      font-family: "proxa" !important;

      [dir='rtl'] & {
        margin-right: 12px;

      }
    }
  }
}