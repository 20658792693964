.addCoupon {
    z-index: 9;
    background-color: rgba(217, 217, 217, 0.8);
    overflow: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    min-height: 100vh;

    .SingleDatePickerInput__rtl {
        [dir='ltr'] & {
            direction: ltr !important;
        }

        [dir='rtl'] & {
            direction: rtl !important;

        }
    }

    .userModule {
        z-index: 999;
        background-color: rgba(217, 217, 217, 0.8);
        overflow: auto;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        min-height: 100vh;

        .selectedItem {
            border: 1.15407px solid #87BE44;
            color: #87BE44;
            background-color: #FBF8F8;

        }

        .userDiv {
            z-index: 999;
            min-height: 680px;
            height: fit-content !important;
            border: 1px solid #8fce41;
            position: relative;
            width: 45%;

            .goback {
                font-size: 22px;
                color: #87BE44;
                position: absolute;
                top: 20px;

                [dir='rtl'] & {
                    left: 18px;
                }
            }

            .searchDiv {
                .searchInp {
                    border-radius: 12px;
                }
            }

            thead,
            th {
                border-top: none !important;
            }

            .nameTh {
                width: 40%;
            }

            .submitDiv {

                bottom: -10px;
            }
        }


    }
}

.addCouponDiv {
    z-index: 9;
    // min-height: 655px;
    height: fit-content !important;
    position: relative !important;






    .helpModule {
        z-index: 999;
        position: absolute;
        top: 0;
        width: 44%;
        top: 40%;
        background: #FBF8F8;
        border: 1px solid #87BE44;
        border-radius: 20px;

        [dir='ltr'] & {
            right: -242px;

        }

        [dir='rtl'] & {
            left: -242px;

        }

    }

    .createBtn {

        background: #87BE44;
        border: 1px solid #FFFFFF;
        border-radius: 30px;
        color: #FAFAFA;
        width: 140px;
        height: 33px;
    }

    .fa-circle-xmark {
        position: absolute;
        top: 4%;
        right: 5%;
        font-size: large;

    }

    .selectedItem {
        border: 1.15407px solid #87BE44;
        color: #87BE44;
        background-color: #FBF8F8;

    }

    .InputsDiv {
        .inputStarting {
            width: 133px;
        }

        .date-range-dayOff {
            .control-field__label {
                display: none !important;
            }

            .control-field {
                margin-left: 2px;
            }

            .control-field__body,
            .control-field {
                border: none !important;
                padding: 0 !important;
            }

            .control-field__body {
                background-color: #FBF8F8 !important;
                text-align: center;

                .date-icon {
                    bottom: -6px;
                }
            }

            .row {
                width: 100%;
                align-items: center;
                display: flex;
                align-items: center;
                background-color: #FBF8F8 !important;

                .SingleDatePickerInput,
                .DateInput_input,
                .DateInput {
                    background-color: #FBF8F8 !important;

                }

            }

            .control-field__feedback {
                display: none !important;
            }

            .pointer {
                margin: 0 5px;
            }

        }
    }

    .couponType {
        .infoIcon {
            border: 2px solid #8fce41;
            border-radius: 50%;
            width: 19px;
            height: 19px;
            text-align: center;
        }

        .types {
            background-color: #FBF8F8;
            border: 1px solid #DBDBDB;
            border-radius: 17px;
            width: 116px;

        }

        .discountContent {
            width: 116px;

        }

        .discountType {
            position: absolute;
            top: 50%;
            left: 124px;
            font-size: inherit;

            [dir='ltr'] & {
                left: 124px;
            }

            [dir='rtl'] & {
                right: 124px;
            }
        }
    }

    .couponAud {
        .couponAudSpanDiv {
            .couponAudSpan {
                background-color: #FBF8F8;
                border: 1px solid #DBDBDB;
                border-radius: 17px;
            }


        }

    }
}