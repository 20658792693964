// Filename: ToggleSwitch.css

.toggle-switch {
    position: relative;
    width: 48px;
    display: inline-block;
    text-align: left;
    top: 12px;

    @media only screen and (max-width: 900px) {
        // left: 35px;
    }
}

.checkbox {
    display: none;
}

.label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
}

.inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}

.inner:before,
.inner:after {
    float: left;
    width: 50%;
    height: 26px;
    padding: 0;
    line-height: 36px;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
}

.inner:before {
    content: "";
    padding-left: 10px;
    background-color: $green;
    color: #fff;
}

.inner:after {
    content: "";
    padding-right: 10px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
}

.switch {
    //the controller
    display: block;
    width: 15px;
    height: 15px;
    margin: 5px ;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 22px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
    @media only screen and (max-width: 900px) {
        right: 11px;

    }
}

.checkbox:checked+.label .inner {
    margin-left: 0;
}

.checkbox:checked+.label .switch {
    right: 0px;
}