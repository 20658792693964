.coachCalendar {
    .pointer {
        top: 30px;
        left: -2px;
    }

    .rbc-event {
        padding: 0 !important;
    }

    .rbc-toolbar .rbc-toolbar-label {
        z-index: 0;
    }

    .rbc-toolbar {
        margin-top: 12px;

        .rbc-toolbar-label {
            font-size: smaller;
            left: 39rem;
        }
    }

    .rbc-time-header .rbc-today button {
        position: absolute;
        height: 78px;

        &::after {
            top: 4rem;
        }
    }

    .date-range {

        [dir='ltr'] & {
            right: -18rem;
            top: 1.7rem;
        }

        [dir='rtl'] & {
            left: -18.5rem;
            top: 2.1rem;

        }
    }
}