.rewardModal {
    .modal-content {
        height: 650px;
        border-radius: 30px;
    }

}

.radioDiv {
    border-radius: 9.807px;
    border: 0.981px solid #CFD3D4;
    height: 59.78px;
    width: 250px;


    &.active {
        border: 0.981px solid #87BE44;

        input[type="radio"] {
            &:checked {
                accent-color: #87BE44;
            }
        }

        label {
            color: #87BE44;
        }
    }

    &.error {
        label {
            color: red;
        }
    }
}