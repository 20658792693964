.otherVideosDiv {
    overflow: auto;

    .videoDiv {
        border-radius: 0px 0px 11px 11px;
        min-height: 200px;

        img {
            border-radius: 11px;
        }
        .btnDiv {
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            border-radius: 0px 0px 11px 11px;
            background-color: $white;
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
        }
        .layerDiv {
            position: absolute;
            top: 0;
            bottom: 0% !important;
            right: 0;
            left: 0;
            height: 68%;
    
           
    
                .videoInfo {
                    height: 100%;
                    position: relative;

                    .courtName {
                        border-radius: 7.846px;
                        background: rgba(79, 80, 78, 0.80);
                        height: 25px;
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        top: 4%;
                        right: 4%;
    
                        p {
                            color: #FFF;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            padding: 10px;
                        }
                    }
    
                    .playVideo {
                        cursor: pointer;
                        width: 60px;
                        height: 60px;
                        border-radius: 30px;
                        background-color: rgba(79, 80, 78, 0.65);
                        position: absolute;
                        top: 24%;
                        left: 40%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
    
    
                    }
    
                    .videoTime {
                        border-radius: 7.846px;
                        background: rgba(79, 80, 78, 0.80);
                        height: 25px;
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        bottom: 4%;
                        right: 4%;
    
                        p {
                            color: #FFF;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            padding: 10px;
                        }
                    }

                    .videoDate {
                        border-radius: 7.846px;
                        background: rgba(79, 80, 78, 0.80);
                        // width: 58.068px;
                        padding-right: 5;
                        padding-left: 5;
                        height: 25px;
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        bottom: 4%;
                        left: 4%;
    
                        p {
                            color: #FFF;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            padding: 10px;
                        }
                    }
                }
        }
    }

    
}