.home-section {
  overflow-y: auto !important;
  height: fit-content;
}

.utilize-box {
  border: 1px solid #EAECEE;
  border-radius: 7px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  min-height: 150px;

  .utilize-data {
    .title-utilize {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    .rate-utilize {
      font-size: 28px;
      font-weight: 600;
    }
  }

  .utilize-rate {
    width: 20%;

    .CircularProgressbar {
      .CircularProgressbar-path {
        stroke: #98c11d !important;
      }
    }

    .CircularProgressbar {
      .CircularProgressbar-trail {
        stroke: #e6fbd9 !important;
      }
    }

    .CircularProgressbar {
      .CircularProgressbar-text {
        fill: #98c11d !important;
      }
    }
  }

  .utilize-revenue {
    align-self: flex-end;
    flex-basis: fit-content;

    .utilize-total {
      font-weight: bold;
      font-size: 28px;
      color: #98c11d;
    }

    .utilize-booking {
      font-weight: bold;
      color: #646161;
      text-transform: uppercase;
    }
  }
}

.filter-box {
  display: flex;
  gap: 15px;
}

.report-select {
  margin: 1rem 0;

  select {
    border: 1px solid #D9D9D9;
    padding: 0.5rem 0.3rem;
    border-radius: 5px;
  }
}

.chart-box {
  border: 1px solid #EAECEE;
  border-radius: 7px;
  padding: 1rem;
  margin-block: 1rem;
  min-height: 405px;

  h4 {
    font-size: 24px;
  }
}