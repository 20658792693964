.ManageStadiumModule {
    .border-left {
        border-left: 1px solid #EAECEE;

    }

    .icon {
        display: none !important;
    }

    .inputwithLabel {
        width: 100% !important;

        .control-field__label {
            display: block;
        }
    }

    .control-field__body {
        background-color: #FBF8F8 !important;
        padding: 2px 5px;
    }

    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 8px 7px !important;
    }

    .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
        padding: 8px 8px 8px 2px;
    }

    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
        padding-right: 8px !important;
    }
}


//Manage stadium
.ManageStadiumTitle {
    h3 {
        margin-left: 5px;
        color: #87BE44;
        border-bottom: 3px #87BE44 solid;
        padding: 0 2px;
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
    }

    .saveBtn {
        border-radius: 8px;
    }


}

.leftPart {

    .openingTimeInps,
    .closingTimeInps {
        width: 50% !important;


    }

    @media only screen and (max-width: 600px) {

        .openingTimeInps,
        .closingTimeInps {
            width: 100% !important;


        }
    }

    .upFront {
        border: 1px solid #EAECEE;
        border-radius: 7px;

        .titleDiv {
            background: rgba(238, 238, 238, 0.55);
            height: 43px;

            h5.title {
                color: #535353;
                font-weight: 700;
            }

            .inner {

                &::before,
                &::after {
                    height: 30px;
                }
            }

            .switch {
                height: 21px;
                width: 21px;
                right: 19px;
                top: -1px;
            }
        }

        .upFrontContent {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .control-field__body {
                background: transparent !important;
            }

            .applyDiv {
                border-radius: 5px;
                border: 1px solid #87BE44;
                width: 189px;
                height: 46px;

                .checkBoxDiv {
                    display: flex;
                    align-items: center;

                    .checkmark {
                        top: 22%;
                        border-radius: 5px;
                        border: 1px solid #87BE44;

                        [dir="ltr"] & {
                            left: 0;
                        }

                        [dir="rtl"] & {
                            right: 0%;

                        }
                    }
                }
            }
        }
    }

    .addBtn {
        border-radius: 8px;
    }

    .Pitches-table {


        td span {
            font-size: $text-caption;


        }

        .controller {
            font-size: large;
        }

        .selectedDate {
            background-color: #1EA66D !important;
            color: #fff !important;

        }


        .subTable {
            font-size: $text-caption;
            // border-collapse: separate;
            border-spacing: 0 6px;
            margin-bottom: 0.3rem;

            td,
            th {
                padding: 0.5rem;
                vertical-align: middle;
                border-top: none;
                border: 0.2px solid #98A39E;
                text-align: center;

                &:first-child {
                    border-radius: 0px 0px 11px 11px;

                    [dir="rtl"] & {
                        border-radius: 0px 0px 11px 11px;

                    }
                }

                &:last-child {
                    border-radius: 0px 0px 8px 8px;


                    [dir="rtl"] & {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                    }
                }
            }





        }
    }

    .daysOff {

        .daysOff-table {
            font-size: 16px;
            // border-collapse: separate;
            // border-spacing: 0 6px;
            margin-bottom: 0.3rem;

            th,
            td {
                border-top: none !important;
                padding: 0;
                border-right: 1px solid #EAECEE;
                text-align: center;
                padding: 5px 0;

                &:last-child {

                    border-right: none;

                }
            }

            td {
                font-size: $text-caption;

                i {
                    font-size: 15px;
                }
            }
        }


    }

    .creatingDaysOff {

        .startingFromDiv {
            .inputStarting {
                width: 133px;
            }

            .date-range-dayOff {
                .control-field__label {
                    display: none !important;
                }

                .control-field {
                    margin-left: 2px;
                }

                .control-field__body,
                .control-field {
                    border: none !important;
                    padding: 0 !important;
                }

                .control-field__body {
                    background-color: #FBF8F8 !important;
                    text-align: center;

                    .date-icon {
                        bottom: -6px;
                    }
                }

                .row {
                    width: 100%;
                    align-items: center;
                    display: flex;
                    align-items: center;
                    background-color: #FBF8F8 !important;

                    .SingleDatePickerInput,
                    .DateInput_input,
                    .DateInput {
                        background-color: #FBF8F8 !important;

                    }

                }

                .control-field__feedback {
                    display: none !important;
                }

                .pointer {
                    margin: 0 5px;
                }

            }
        }
    }


}

.rightPart {
    .fa-circle-xmark {
        position: absolute;
        top: -5px;
        right: 5px;
        background-color: #F6BDBD;
        color: #A61E1E;
        font-size: 22px;
        border-radius: 50%;
    }
}