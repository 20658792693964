/* ----------------------------------------------------------------------------------------------------------------------------------------------------
    01. Text Sizes - Class
---------------------------------------------------------------------------------------------------------------------------------------------------- */
@for $i from 8 through 72 {
	.text-#{$i} {
		font-size: $i / 16 + rem !important;
	}
}

/* ----------------------------------------------------------------------------------------------------------------------------------------------------
    04. Text Colors - Classes
---------------------------------------------------------------------------------------------------------------------------------------------------- */
.black-text {
	color: $black !important;
}

.white-text {
	color: $white !important;
}

.text-success {
	color: $green !important;
}

.text-danger {
	color: $red !important;
}

.text-warning {
	color: $orange !important;
}

.text-primary {
	color: $blue !important;
}

.text-maroon {
	color: #A61E1E !important;
}

.text-darkgrey {
	color: $dark-gray !important;
}

.gray-color {
	color: $gray-300 !important;
}

.dark-blue-color {
	color: $dark-blue !important;
}

.bg-dark-blue-color {
	background: $dark-blue !important;
}

.bg-blue-black {
	background: $blueBlack;
}

.text-blue-black {
	color: $blueBlack;
}

.bg-white-blue {
	background: $whiteBlue !important;
}

.text-gray-300 {
	color: $gray-300;
}

.text-gray {
	color: #454545;
}

.text-lemon {
	color: $lime;
}

.border-lemon {
	border: 1px solid #8fce41;
}

.bg-gray-100 {
	background: $gray-100;
}

.bg-transparent {
	background: transparent !important;
}

.bg-light-grey {
	background-color: #FBF8F8;
}

.border-lightgrey {
	border: 0.805195px solid #DBDBDB;

}

.text-dgrey {
	color: #454545 !important;
}

.bg-lightGreen {
	background: $lightGreen;
}

/* ----------------------------------------------------------------------------------------------------------------------------------------------------
    05. Text Weights - Class
---------------------------------------------------------------------------------------------------------------------------------------------------- */
.bold-text {
	font-weight: bold;
}

.bolder-text {
	font-weight: bolder;
}

.font-regular {
	font-weight: $font-weight-regular;
}

.font-medium {
	font-weight: $font-weight-medium;
}

.font-bold {
	font-weight: $font-weight-bold;
}

.font14 {
	font-size: $font14 !important;
}

.font12 {
	font-size: 10px !important;

}

.fontsmall {
	font-size: 13px !important;

}

/* ----------------------------------------------------------------------------------------------------------------------------------------------------
    06. Text Sizes - Class
---------------------------------------------------------------------------------------------------------------------------------------------------- */
.text-title {
	font-size: $text-title;
}

.text-title2 {
	font-size: $text-title2;
}

.text-title3 {
	font-size: $text-title3;
}

.text-title4 {
	font-size: $text-title4;
}

.text-title5 {
	font-size: $text-title5;
}

.text-subtitle {
	font-size: $text-subtitle;
}

.text-headline {
	font-size: $text-headline !important;
}

.text-body {
	font-size: $text-body !important;
}

.text-caption {
	font-size: $text-caption !important;
}

.text-caption2 {
	font-size: $text-captio2 !important;
}

.text-caption3 {
	font-size: $text-captio3 !important;
}

.text-font10 {
	font-size: $font10;
}

.text-font13 {
	font-size: $font13;
}

.btn-danger {
	background: $red !important;
	border: 0;
}

.btn-success {
	background: $green !important;
	border: $green;
}

.p-1-25 {
	// padding: 1rem !important;
	padding: 0.5rem !important;
}

textarea {
	resize: none;
}

.text-right {
	text-align: right !important;

	[dir="rtl"] & {
		text-align: left !important;
	}
}

.w-40 {
	width: 40% !important;
}
.w-45 {
	width: 45% !important;
}

.w-47 {
	width: 45% !important;
}

.vh-50 {
	height: 50vh !important;
}

.w-60 {
	width: 60% !important;
}

.min-w-120 {
	min-width: 120px;
}

.w-30 {
	width: 30% !important;
}

.w-35 {
	width: 35% !important;
}

.w-10 {
	width: 10% !important;

}

.w-20 {
	width: 20% !important;

}

.w-15 {
	width: 15% !important;

}

.w-12 {
	width: 12% !important;

}

.w-85 {
	width: 85% !important;
}

.w-60 {
	width: 60%;
}

.w-48 {
	width: 48% !important;
}

.border-rad10 {
	border-radius: 10px;
}

.cursor-pointer {
	cursor: pointer !important;
}

.overflow-hidden {
	overflow: hidden !important;
}

.flex-columns {
	flex-direction: column !important;
}

.fw-bold {
	font-weight: 700;
}

.fw-semibold {
	font-weight: 600;

}
.fw-light {
	font-weight: 400;
}
.italic{
	font-style: italic;
}
.gray-500{
	color: $gray-500;
}
