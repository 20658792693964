.manageStadium-module {
    .btn-default {
        background-color: #87BE44 !important;

    }

    .body {

        .control-field {
            border-radius: 5px;
            margin: 0.25rem 0 !important;

            .control-field__label {
                color: #5E6366;
                font-size: 0.85rem;
                font-weight: 300;
            }

            .control-field__input {
                color: $lime;
            }
        }

        .stadiumDetails {
            overflow: auto;

            .timePicker .textTimePicker .MuiInputBase-root input,
            .DateInput input {
                color: $lime;
            }
        }

        .timePicker .textTimePicker .MuiInputBase-root .MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-standard {
            [dir='ltr'] & {
                margin: 0px 3px 20px 0px;

            }

            [dir='rtl'] & {
                margin: 0px 3px 10px -9px;

            }
        }

        .box {
            border: 1px solid #eaecee;
            border-radius: 7px;

            .titleDiv {
                background: rgba(238, 238, 238, 0.55);
                min-height: 43px;

                h5.title {
                    color: #535353;
                    font-weight: 700;
                }
            }

            .stadiumAndDaysOff {
                .upFront {
                    .titleDiv {
                        .inner {

                            &::before,
                            &::after {
                                height: 30px;
                            }
                        }

                        .switch {
                            height: 21px;
                            width: 21px;
                            right: 19px;
                            top: -1px;
                        }
                    }
                }

                .upFrontContent {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .control-field__body {
                        background: transparent !important;
                    }


                }
            }
        }

        .galleryImages {

            .delete {
                background-color: $darkRed;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                position: absolute;
                right: 0%;
                top: -4%;
                cursor: pointer;

                i {
                    color: #fff;
                }
            }
        }

        .galleryImage,
        .coverImage {
            border-radius: 5px;
        }

        .galleryImages {
            .galleryImagesOfStadium {
                max-height: 65vh;
                overflow-y: auto;
            }
        }
    }
}