.profile-section {
  background-color: #cbe08e;
  min-height: 200px;
  display: flex;
  flex-direction: column-reverse;
  place-items: flex-end;
  font-size: 25px;
  color: $green;
}

.user-profile {
  .img-profile {
    margin-top: -80px;
    position: relative;
    display: inline-block;
    cursor: pointer;
    .img-box {
      width: 115px;
      height: 115px;
      line-height: 115px;
      border-radius: 50%;
      overflow: hidden;
      display: inline-block;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .icon-img {
      position: absolute;
      right: 0;
      bottom: 10px;
      i {
        width: 35px;
        height: 35px;
        line-height: 35px;
        background-color: $green;
        text-align: center;
        border-radius: 50%;
        color: $white;
      }
    }
  }
  .profile-title {
    padding-inline: 9rem;
    margin-top: -38px;
    font-size: 18px;
    color: $green;
  }
}

.profile-form {
  margin-top: 5rem;
}
