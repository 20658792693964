.myContainer {
    .stepper.MuiBox-root {
        [dir='ltr'] & {
            right: 15%;
        }

        [dir='rtl'] & {
            left: 15%;
        }
    }

    section {
        margin-top: 16px;

        .sectionTitle {
            font-weight: 400;
            font-size: 14px;
        }

        .control-field {
            border-radius: 8px;
            border: 1px solid var(--black-1, #CFD3D4);

            .control-field__body {
                padding: 0.1rem;

                input,
                .control-field__select {
                    padding-top: 7px;
                    color: #5a5c5d;
                }

                label.control-field__label {
                    font-size: 12px;
                    color: #23272a;
                    font-weight: 400;
                }

                .selected-flag .arrow {
                    [dir='ltr'] & {
                        left: 29px;
                    }

                    [dir='rtl'] & {
                        right: 29px;
                    }
                }
            }

        }

        .coachBioText {
            .control-field {
                margin-bottom: 0 !important;
            }

            .input-group.control-field {

                height: 114px;
            }
        }

        &.languageSec,
        &.paymentMethods,
        &.availabiliy_section {
            .checkBoxDiv {
                display: flex;
                align-items: center;

                .checkmark {
                    border-radius: 5px;
                    border: 1px solid #87BE44;

                    [dir="ltr"] & {
                        left: 0;
                    }

                    [dir="rtl"] & {
                        right: 0%;

                    }
                }
            }



        }

        &.pricing {
            .priningInp {
                border-radius: 5px;
                background: rgba(238, 238, 238, 0.55);
                width: 59px;
                height: 38px;
                border: 0;
                border-bottom: 1px solid #87BE44;

            }

            label {
                color: #83898C;
                font-weight: 400;

            }
        }
    }


    .insertPhoto {
        background-color: #F7FFEE;


        .cameraWithImage {
            top: 29%;
            z-index: 1;
        }

        .camera {
            top: 25%;
            z-index: 1;

            [dir='rtl'] & {
                left: 30%;
            }

            [dir='ltr'] & {
                right: 30%;
            }
        }

        .coachImage {
            width: 150px;
            height: 188px;

            .circle {
                display: none;
                transition: all 1s ease-in;
            }

            &:hover {
                .circle {
                    display: flex;
                    position: absolute;
                    top: 0;
                    background-color: #4F504EB2;


                }

                .cameraWithImage {
                    filter: brightness(10);

                }
            }

        }

    }


    .form-control {
        font-size: 12px !important;
        border: none !important;
        background: #FFFFFF;
        border: 1px solid #CACACA;
        border-radius: 5px;
        outline: none;
        width: 100% !important;
        padding: 7px 14px 7.5px 60px !important;

        &:focus {
            box-shadow: none !important;
        }

        &::after {
            content: "";
            width: 2px;
            height: 3px;
            color: #1EA66D;
            display: inline-block;
        }

    }
}