.viewVideoComponent {
    background: rgba(79, 80, 78, 0.85);
    overflow: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    min-height: 100vh;
    z-index: 99;

    .fa-regular {
        color: $green;
        position: absolute;
        top: 35px;
        font-size: 2rem;
        right: 60px;
        cursor: pointer;
    }

    .deletePitchDiv {
        height: fit-content !important;
        border-radius: 15px;
    }

    .waterMark {
        width: 50%;
        top: 23%;
        right: 26%;
        position: absolute;
        position: absolute;
    }
}