.control-field {
  background-color: $white;
  border: 1px solid $gray-200;
  border-radius: calculateRem(5px);
  font-size: $text-caption;
  padding: 0.25rem !important;

  .control-field__body {
    padding: 0.25rem !important;
  }

  .phone-input {
    padding: 0 !important;
    display: flex;
    align-items: center;


  }

  &--danger {
    border: 1px solid $red !important;
  }

  &--success {
    border: 1px solid $green;
  }

  &--warning {
    border: 1px solid $orange;
  }

  &__label {
    font-size: calculateRem(12px);
    font-weight: $font-weight-medium;
    color: $dark-blue;
    margin-bottom: 0;
    width: 100%;
    // text-align: var(--left);
    text-align: var(--left);

    &--danger {
      color: $red !important;
    }

    &--success {
      color: $green;
    }

    &--warning {
      color: $orange;
    }
  }

  &__prepend {
    // padding: 0.625rem;
    padding: inherit;
    // border-#{var(--right)}: 1px solid $gray-200;
    border-inline-end: 1px solid $gray-200;

    &--danger {
      // border-#{var(--right)}: 1px solid $red;
      border-inline-end: 1px solid $red;
    }

    &--success {
      // border-#{var(--right)}: 1px solid $green;
      border-inline-end: 1px solid $green;
    }

    &--warning {
      // border-#{var(--right)}: 1px solid $orange;
      border-inline-end: 1px solid $orange;
    }
  }

  &__append {
    padding: calculateRem(10px);
    // border-#{var(--left)}: 1px solid $gray-200;
    border-inline-start: 1px solid $gray-200;

    &--danger {
      // border-#{var(--left)}: 1px solid $red;
      border-inline-start: 1px solid $red;
    }

    &--success {
      // border-#{var(--left)}: 1px solid $green;
      border-inline-start: 1px solid $green;
    }

    &--warning {
      // border-#{var(--left)}: 1px solid $orange;
      border-inline-start: 1px solid $orange;
    }
  }

  &__body {
    // padding: 0.7rem 0.625rem;
    flex: 1 1 auto;
    border-radius: 0.3125rem;

    .icon {
      text-align: center;
    }

    .date-icon {
      position: absolute;
      // #{var(--right)}: 7px;
      right: 0px;
      bottom: 3px;
      font-size: $text-headline;
      color: $gray-300;
      font-weight: normal;
      z-index: 1;

      [dir='rtl'] & {
        right: auto;
        left: 0px;
      }
    }
  }

  &__input {
    border: 0;
    font-size: calculateRem(14px);
    width: 90%;
    color: $dark-blue;
  }

  &__select {
    display: inline-block;
    width: 100%;
    height: auto;
    padding: 0;
    font-size: $text-caption;
    vertical-align: middle;
    // background: #fff url("../../../assets/images/svg/down-chevron.svg")
    // 	no-repeat var(--right) 0 center/10px 10px;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &__feedback {
    font-size: $text-caption;
    display: none;
    color: $dark-blue;
    width: 100%;
    text-align: var(--left);
    margin-top: calculateRem(5px);

    &--danger {
      color: $red;
    }

    &--success {
      color: $green;
    }

    &--warning {
      color: $orange;
    }

    &.error-message {
      // margin-block-start: -8px;
      // padding-inline-start: 5px;
      color: $red;
      // border: 1px solid $red !important;
    }
  }

  // input placeholder
  ::-webkit-input-placeholder {
    /* Edge */
    color: $gray-300;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $gray-300;
  }

  ::placeholder {
    color: $gray-300;
  }

  //select box
  select option {
    color: $dark-blue;
  }

  select:not(:checked) {
    color: $gray-300;
  }

  &__results-list {
    position: absolute;
    width: 100%;
    z-index: 999999;
    top: calculateRem(65px);
    background: $white;
    border: 1px solid $gray-150;
    overflow: scroll;
    max-height: calculateRem(200px);
    border-radius: calculateRem(5px);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  }

  &__result {
    font-size: calculateRem(16px);
    padding: calculateRem(10px);
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid $gray-150;
    }

    &:hover {
      background: $blue;
      color: $white;
    }
  }
}

.errorField {
  border: 1px solid $red !important;
}

.timePicker {

  .textTimePicker {
    height: 18px;

    .MuiInputBase-root {
      font-size: 0.8rem !important;
      height: 24px !important;

      .MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-standard {
        margin: 0px 9px 12px 0px;

      }

      input {
        [dir='rtl'] & {
          text-align: right;
        }

        [dir='ltr'] & {
          text-align: left;

        }
      }

      &::before {
        border: none !important;
      }
    }

  }

}