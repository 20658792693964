//Calendar
.title {
  margin-inline: 5rem;
}

.rbc-calendar {
  height: 85vh !important;
}

.rbc-toolbar {
  flex-direction: row-reverse;
  justify-content: flex-end !important;
  margin-inline: 5rem;
  margin-bottom: 1rem !important;
  width: fit-content !important;

  span {
    &:last-child {
      display: flex;
      flex-direction: row-reverse;

      button {
        border-radius: 20px !important;
        padding-inline: 2.5rem;
        padding-block: 10px;
        margin: 0 -15px;
        position: relative;
        left: 11px;
        border: none !important;
        background-color: #f1f1f1;

        &.rbc-active {
          background: black;
          color: white;
          border: none;
          z-index: 1;
        }
      }
    }
  }

  .rbc-btn-group {
    &:first-child {
      button {
        &:first-child {
          display: none;
        }

        &:nth-child(2) {
          &:before {
            content: '\f053';
            font-family: 'Font Awesome 6 Free';
            padding-inline: 3px;

            [dir='rtl'] & {
              content: '\f054';
            }
          }
        }

        &:nth-child(3) {
          &:after {
            content: '\f054';
            font-family: 'Font Awesome 6 Free';
            padding-inline: 3px;

            [dir='rtl'] & {
              content: '\f053';
            }
          }
        }

        border: none;
        font-weight: 600;
        border-radius: 5px !important;
      }
    }

    &:last-child {
      margin-right: 2rem;

      [dir='rtl'] & {
        margin-left: 2rem;
        margin-right: 1rem;
      }
    }
  }
}

.rbc-toolbar-label {
  font-weight: 600;
  text-transform: uppercase;
  padding-left: 2rem !important;

  [dir='rtl'] & {
    left: auto;
    right: 50rem;
    padding-right: 1.1rem !important;

  }

  @media (max-width: 575px) {
    position: static;
  }
}

.rbc-time-header-cell {
  .rbc-header {
    // padding-block: 25px;
    padding-block: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;

    button {
      font-weight: 600;
    }
  }
}

.rbc-events-container {
  cursor: pointer;
}

.rbc-events-container {
  margin-right: 0 !important;
}

.rbc-event {
  padding: 0 !important;
  z-index: 2;
  overflow: hidden !important;
  border-radius: 8px !important;
  width: 95% !important;
  left: 2.5% !important;
  border: 1px grey solid !important;

  .rbc-event-content {

    .rbcEventContentH1 {
      padding-bottom: px;
    }

    &::-webkit-scrollbar {
      width: 7px
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #323131;
      border-radius: 10px;
    }

  }
}

p {
  font-size: 11px;
}

.imgDiv {
  width: 25%;
}

.point {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #f1f1f1;
}

.rbc-timeslot-group {
  border: none;
  z-index: 1;
  // min-height: 70px !important;

  .rbc-time-slot {


    &:nth-child(even) {
      background: #f3f3f3;
    }

    &:nth-child(odd) {
      background: $white;
    }

    &:hover {
      transition: all 0.3s ease-in-out;
      background-color: #a9cb4a61 !important;
      position: relative;
      cursor: pointer;

      &:before {
        transition: all 0.3s ease-in-out;
        font-family: 'Font Awesome 5 Free';
        font-weight: 400;
        content: '\2b';
        position: absolute;
        left: 47%;
        top: 30%;
        width: 14px;
        height: 14px;
        line-height: 14px;
        font-size: 12px;
        text-align: center;
        border-radius: 20%;
        color: white;
        background: #b1d055;
      }
    }
  }
}

.rbc-day-slot {
  .rbc-time-slot {
    border-top: none !important;
    min-height: 25px;

  }
}

.rbc-time-gutter {

  .rbc-timeslot-group,
  .rbc-time-slot {
    background: white !important;
    min-height: 25px;


    &:hover {
      background-color: inherit !important;
      cursor: inherit;

      &:before {
        content: none;
      }
    }
  }
}

.rbc-time-view {
  border-radius: 5px;
  overflow: hidden;
}

.rbc-time-view-resources {
  .rbc-header {
    padding-block: 10px;
    font-size: 18px;
  }
}

.rbc-time-header {
  &.rbc-overflowing {
    margin: 0 !important;
    padding-right: 11px;
    background: #f3f3f3;

    [dir='rtl'] & {
      padding-right: initial;
      padding-left: 11px;
    }
  }

  .rbc-header {
    background: #f3f3f3;
    border-bottom: 0;

    button {
      >span {
        width: 45px;
        display: flex;
        white-space: break-spaces;
        font-size: 20px;
      }
    }
  }

  .rbc-today {
    position: relative;

    button {
      background: #98c11d;
      position: fixed;
      height: 90px;
      width: 90px;
      padding-inline: 1rem;
      border-radius: 20px;
      color: white;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: center;

      >span {
        width: 51px;
        height: 75px;
        display: flex;
        white-space: break-spaces;
        font-size: 20px;
      }

      &::after {
        content: '';
        position: absolute;
        top: 4.8rem;
        left: 47%;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: $white;
      }
    }
  }

  .rbc-row.rbc-row-resource {
    border-bottom: 0 !important;
  }
}

.rbc-timeslot-group {
  border-bottom: none !important;
}

.rbc-time-content {
  border-top: none !important;
}

.rbc-time-view {
  border: none !important;
}

.rbc-time-header-gutter {
  background-color: $white;

  [dir='rtl'] & {
    background-color: #f3f3f3 !important;
  }
}



.rbc-time-header-content {
  border-left: none !important;
}

.rbc-time-content>*+*>* {
  border-left: none !important;
}


.rbc-allday-cell {
  display: none;
}


.selectsDiv {
  top: 3.2rem;

  .pitch-select {
    top: 5rem;
    z-index: 1;
    width: 185px;

    select {
      background: $green;
      color: white;
      border: none;
      padding: 0rem 1.3rem;
      border-radius: 5px;
      width: 175px;
      height: 100%;
    }

    @media (max-width: 575px) {
      position: static;
      text-align: center;
    }
  }

  .stadium-select {
    // right: 13.5rem;
    z-index: 9;
    width: 215px;

    .basic-single {
      [class*="control"] {
        border-color: $green !important;

        [class*="singleValue"] {
          color: $green !important;
        }
      }

    }

    .ant-select-single {
      color: white;
      width: 100%;

      .ant-select-selection-item {
        font-size: 16px;
        font-weight: 600 !important;
      }
    }

    .ant-select-selector {
      width: 100% !important;
      height: 37px !important;
      background: #98c11d !important;
      color: white !important;
      font-weight: 600 !important;

    }

  }
}



.rbc-slot-selecting {
  cursor: not-allowed !important;
}

.rbc-time-content .rbc-time-column {
  border-left: 1px solid #ddd !important;
}

.btn.disabled,
.btn:disabled {
  opacity: 1;
  border: none;
  color: black;
  box-shadow: none !important;
  outline: none;
}

.date-range {
  position: relative;
  width: 0px;
  margin-top: -60px;
  z-index: 1049;

  [dir='ltr'] & {
    right: -18rem;
    top: 2.1rem;

  }

  [dir='rtl'] & {
    top: 2.5rem;
    left: -17.5rem;

  }

  div {
    border: none !important;
  }

  .control-field__body {
    z-index: -3 !important;
    border: 0 !important;
  }

  .control-field__label {
    display: none !important;
  }

  i {
    cursor: pointer;
  }

  .date-icon {
    display: none;
  }

  .SingleDatePicker_picker {
    margin-top: 15px !important;

  }

  .CalendarMonthGrid__horizontal {
    [dir='ltr'] & {
      transform: translateX(0px);
      left: 9px;
    }

    [dir='rtl'] & {
      transform: translateX(292px) !important;
      right: 0px !important;
      left: 0% !important;

    }
  }

}

.impossible-dates {
  li {
    color: #ff5f59;
    font-size: 16px;
    font-weight: 600;
  }
}



.rbc-event-label {
  display: none !important;
}

.calendarDayOff {
  background-color: rgb(193, 192, 192) !important;
  border: 1px solid #fff;

  &:before {
    content: "Closed";
    font-family: Arial, sans-serif;
    font-weight: 600;
    position: absolute;
    margin: 1px 0px;
    width: 100%;
    height: 25px;
    padding: 2px;
    left: 2px;
    text-align: center;
    line-height: 18px;
    font-size: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    text-align: center;
  }



  &:not(.nohover):hover {
    background-color: grey !important;

    &:before {
      content: "\f057";
      color: black;
      background-color: transparent;
    }
  }
}

.FemaleFriendlySlot {
  background-color: #ffe4f3 !important;
  min-height: 25px !important;
  border: 0.3px solid #fff;

  &:before {
    content: "Female Friendly" !important;
    font-family: Arial, sans-serif;
    font-weight: 600;
    position: absolute;
    left: 1px;
    width: 100%;
    height: 25px;
    line-height: 14px;
    font-size: 0.6rem !important;
    border-radius: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #e216b3;
    text-align: center;
    background: transparent !important;
  }

  &:not(.nohover):hover {
    transition: all 0.3s ease-in-out;
    position: relative;
    cursor: pointer;

    &:before {
      transition: all 0.3s ease-in-out;
      font-family: 'Font Awesome 5 Free';
      font-weight: 400;
      content: '\2b' !important;
      position: absolute;
      left: 47%;
      top: 30%;
      width: 14px;
      height: 14px;
      line-height: 14px;
      font-size: 12px;
      text-align: center;
      border-radius: 20%;
      color: white;
      background: #b1d055 !important;
    }
  }
}

.DiscountSlot {

  &:before {
    content: "";
    font-family: Arial, sans-serif;
    font-weight: 600;
    position: absolute;
    margin: 1px 0px;
    width: 100%;
    height: 25px;
    border: 1px solid #fff;
    padding: 2px;
    text-align: center;
    line-height: 18px;
    font-size: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    background: rgba(255, 249, 195, 0.60);
  }

  &:hover {
    transition: all 0.3s ease-in-out;
    background-color: #a9cb4a61;
    position: relative;
    cursor: pointer;

    &:before {
      transition: all 0.3s ease-in-out;
      font-family: 'Font Awesome 5 Free';
      font-weight: 400;
      content: '\2b';
      position: absolute;
      left: 47%;
      top: 30%;
      width: 14px;
      height: 14px;
      line-height: 14px;
      font-size: 12px;
      text-align: center;
      border-radius: 20%;
      color: white;
      background: #b1d055;
      border: none;
    }
  }

}

.FemaleDiscountSlot {
  background-color: #ffe4f3 !important;
  min-height: 25px !important;
  border: 0.3px solid #fff;

  &:before {
    content: "";
    font-family: Arial, sans-serif;
    font-weight: 600;
    position: absolute;
    margin: 1px 0px;
    width: 50%;
    height: 25px;
    border: 1px solid;
    left: 2px;
    box-shadow: 0 0 darkgrey;
    text-align: center;
    line-height: 14px;
    font-size: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    background: rgba(255, 249, 195, 0.60);
  }

  &:after {
    content: "Female Friendy";
    font-family: Arial, sans-serif;
    font-weight: 600;
    position: absolute;
    margin: 1px 0px;
    width: 50%;
    height: 25px;
    border: 1px solid #fff;
    right: 2px;
    box-shadow: 0 0 darkgrey;
    text-align: center;
    line-height: 14px;
    font-size: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #e216b3;
    text-align: center;
    background: #ffe4f3;
  }

  &:hover {
    &:after {
      transition: all 0.3s ease-in-out;
      font-family: 'Font Awesome 5 Free';
      font-weight: 400;
      content: '\2b';
      position: absolute;
      left: 47%;
      top: 30%;
      width: 14px;
      height: 14px;
      line-height: 14px;
      font-size: 12px;
      text-align: center;
      border-radius: 20%;
      color: white;
      background: #b1d055;
    }
  }

}

.rbc-time-column:first-child {
  .rbc-timeslot-group {
    .rbc-time-slot {
      &:before {
        content: "" !important;
        background-color: transparent;
        border: none;
      }

      &:hover {
        background-color: transparent !important;
      }
    }
  }
}

.modal-dialog {
  height: 100% !important;
}