.loader {
	background-color: $gray-150;
	color: $dark-blue;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	height: 100vh;
	z-index: 99999;
}

.inner-loader {
	background-color: #0000005c;
	color: $dark-blue;
	position: fixed;
	right: 0;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 99999;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		max-width: 50px;
	}

	&.left-17 {
		left: 17.5% !important;
		right: 0 !important;

		[dir='rtl'] & {
			right: 17.5% !important;
			left: 0 !important
		}
	}
}