.billing-module {
    overflow-y: auto;
    min-height: 100vh;

    @media only screen and (max-width: 900px) {
        width: 100%;
        overflow-x: auto;

    }

    .selectStadiumBilling {
        z-index: 9;

        @media only screen and (max-width: 900px) {
            width: 100% !important;

        }
    }
}

.nav-pills {
    width: 50%;

    @media only screen and (max-width: 900px) {
        width: 100%;

    }

    .nav-item {
        width: 35%;

        @media only screen and (max-width: 900px) {
            width: 50%;

        }

        @media only screen and (min-width: 700px) and (max-width: 1024px) {
            width: 40%
        }

        ;
    }

    .nav-link {
        color: #4F504E !important;
        transition: all 1s;
        background-color: transparent !important;
        text-align: center;
        line-height: normal;
        padding: 0;
        font-weight: 700;

        &::after {
            content: "";
            background-color: #4F504E;
            display: inline-block;
            height: 3px;
            width: 100%;
        }

    }

    .nav-link.active {
        color: #88BE46 !important;


        &::after {
            content: "";
            background-color: #88BE46;
            display: inline-block;
            height: 3px;
            width: 100%;
        }
    }
}