.AddonBox {
    border-radius: 8px !important;
    width: 98%;

    .details {
        border-radius: 8px 8px 0 0;

        .addonTitle {
            color: $dark-gray;
            font-size: $text-title3;
            font-weight: 700;

        }

        .addonPrice {
            border: 4px solid $lime;
            border-radius: 8px;
            font-size: text-subtitle;
            background-color: transparent;
            width: 120px;

        }
    }

    .footer {
        background-color: #E4E5E5;
        border-radius: 0 0 8px 8px;

    }
}